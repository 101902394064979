var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { Suspense, memo } from "react";
import * as styles from "./styles.module.sass";
import { HashRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./components/Login";
import AuthenticatedRoutes from "./AuthenticatedRoutes/index";
import NavBar from "./components/NavBar";
import Header from "./components/Header";
import ContactSupport from "./components/CallsLogs/ContactSupport";
import { DefaultLoader } from "./components/LoadingPageInfo/index";
import { useSelect } from "./helper/use-select";
import history from "./helper/history";
import { getCountries } from "./redux-store/actions/get-countries-actions";
import { authenticateSocket, disconnectSocket, } from "./redux-store/actions/socket-actions";
import { clearPrevOrderDetailsAction } from "./constants/index";
import ErrorBoundary from "./components/ErrorBoundary";
// Memoized components
var MemoizedNavBar = memo(NavBar);
var MemoizedHeader = memo(Header);
var MemoizedContactSupport = memo(ContactSupport);
// Toast container styles
var toastStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};
function App() {
    var _this = this;
    var dispatch = useDispatch();
    var _a = useSelect(function (state) { return state.authReducer; }), isLoggedIn = _a.isLoggedIn, token = _a.token;
    var loadingStatus = useSelect(function (state) { return state.storeReducer; }).loadingStatus;
    var _b = React.useState(false), isInitialized = _b[0], setIsInitialized = _b[1];
    React.useEffect(function () {
        var initializeApp = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (isLoggedIn) {
                    dispatch(getCountries());
                    dispatch(authenticateSocket({ token: token }));
                    setIsInitialized(true);
                }
                else {
                    setIsInitialized(true);
                }
                return [2 /*return*/];
            });
        }); };
        initializeApp();
        return function () {
            if (isLoggedIn) {
                dispatch(disconnectSocket());
            }
        };
    }, [isLoggedIn, token, dispatch]);
    React.useEffect(function () {
        if (history.location.pathname === "/wall") {
            dispatch(clearPrevOrderDetailsAction());
        }
    }, [history.location.pathname, dispatch]);
    if (!isInitialized || (!loadingStatus && loadingStatus === "loading")) {
        return (React.createElement("div", { className: styles.App },
            React.createElement("div", { className: styles.loaderWrapper },
                React.createElement(DefaultLoader, null))));
    }
    if (!isLoggedIn) {
        return (React.createElement("div", { className: styles.App },
            React.createElement(Login, null)));
    }
    return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: styles.App },
            React.createElement(HashRouter, null,
                React.createElement(Suspense, { fallback: React.createElement(DefaultLoader, null) },
                    React.createElement(ToastContainer, { position: "top-center", hideProgressBar: true, draggable: true, pauseOnHover: true, style: toastStyles }),
                    React.createElement(MemoizedHeader, null),
                    React.createElement(MemoizedNavBar, null),
                    React.createElement("div", { className: styles.separator }),
                    React.createElement(AuthenticatedRoutes, null),
                    React.createElement(MemoizedContactSupport, null))))));
}
export default memo(App);
